<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="10" sm="8" md="6" lg="4">
        <v-card>
          <v-toolbar :color="actionColor" dark>
            <v-toolbar-title>{{ $helper.tRoute($route.name) }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form id="form" ref="form">
              <v-text-field
                v-model="form.email"
                id="email"
                :label="$t('Cols.users.email')"
                maxlength="35"
                ref="email"
                :rules="[rules.required, rules.email]"
                validate-on-blur
              ></v-text-field>
              <v-text-field
                v-model="form.password"
                @click:append="showPassword = !showPassword"
                :append-icon="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
                id="password"
                maxlength="20"
                :label="$t('Cols.users.password')"
                ref="password"
                :rules="[rules.required]"
                :type="showPassword ? 'text' : 'password'"
                validate-on-blur
              ></v-text-field>
              <v-checkbox
                v-model="form.remember"
                id="remember"
                :color="actionColor"
                :label="$t('Cols.users.remember')"
                ref="remember"
              ></v-checkbox>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="login" block :color="actionColor" dark id="login"
              >{{ $t("Auth.login") }}
            </v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn
              @click="
                $router.replace({
                  name: 'forgotPassword',
                  query: { email: form.email },
                })
              "
              block
              :color="actionColor"
              dark
              id="forgotPassword"
              text
              >{{ $t("Auth.forgotPassword") }}
            </v-btn>
          </v-card-actions>
          <v-card-actions v-if="show">
            <v-btn
              @click="$router.replace({ name: 'register' })"
              block
              :color="actionColor"
              dark
              id="wantToRegister"
              text
              >{{ $t("Auth.wantToRegister") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Login",

  data() {
    return {
      actionColor: this.$settings.appColor,

      form: {
        email: "",
        password: "",
        remember: "",
      },

      loading: false,

      rules: {
        email: (v) => /.+@.+\..+/.test(v) || this.$t("Auth.validEmail"),
        required: (v) => !!v || this.$t("fieldRequired"),
      },

      showPassword: false,
    };
  },

  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),

    passwordLength() {
      return process.env.VUE_APP_APP_ENV == "local" ? 1 : 8;
    },
  },

  watch: {
    authUser(val) {
      if (val) {
        if (this.$route.query.intended) {
          this.$router.replace({ name: this.$route.query.intended });
        } else if (process.env.VUE_APP_LOGIN_NEXT_ROUTE != "") {
          this.$router.replace({ name: process.env.VUE_APP_LOGIN_NEXT_ROUTE });
        } else {
          this.$router.replace({ name: "home" });
        }
      }
    },
  },

  created() {
    if (this.$route.query.email) this.form.email = this.$route.query.email;
  },

  methods: {
    ...mapActions({
      authLogin: "auth/login",
      alertError: "app/alertError",
      alertSuccess: "app/alertSuccess",
      alertWarning: "app/alertWarning",
    }),

    error(e) {
      if (this.$helper.error(e) == "invalidData") {
        this.$helper.setFieldErrors(e, this);
        this.alertError(this.$t("invalidData"));
      } else {
        let message = this.$t("Auth.loginError") + this.$helper.error(e);
        this.alertError(message);
      }
    },

    login() {
      if (this.validForm()) {
        this.loading = true;
        this.authLogin(this.form)
          .catch((e) => this.error(e))
          .finally(() => (this.loading = false));
      }
    },

    validForm() {
      if (this.$refs.form.validate()) return true;

      this.alertWarning(this.$t("invalidData"));
    },
  },
};
</script>
